<template>
  <div class="iss-main iss-detail">
    <a-spin :spinning="spinning" size="large">
      <a-row type="flex" align="middle">
        <a-col :span="16">
          <a-space direction="vertical">
            <a-space :size="20">
              <span class="fw-6 fs-20">{{ leadDetail.name }}</span>
              <span>创建时间：{{ leadDetail.createTime }}</span>
            </a-space>
            <a-space :size="40">
              <span>数据来源：{{ leadDetail.sourcesName || '-' }}</span>
              <span>评分模型：{{ leadDetail.scoreName || '-' }}</span>
            </a-space>
          </a-space>
        </a-col>
        <!--        <a-col :span="8">-->
        <!--          <a-row>-->
        <!--            <a-col :span="8" >-->
        <!--              <a-statistic-->
        <!--                :value="leadDetail.totalNumber || 0"-->
        <!--                :value-style="{ color: '#5979F8', 'font-size': '38px' }"-->
        <!--              />-->
        <!--              <a-statistic-->
        <!--                value="线索总数"-->
        <!--                value-style="font-size: 15px;color:#616c8d"-->
        <!--              >-->
        <!--                <template #prefix-->
        <!--                  ><LineChartOutlined style="color: #5979f8; font-size: 22px" />-->
        <!--                </template>-->
        <!--              </a-statistic>-->
        <!--            </a-col>-->
        <!--            <a-col :span="8">-->
        <!--              <a-statistic-->
        <!--                :value="leadDetail.addLeadNumber || 0"-->
        <!--                :value-style="{ color: '#5979F8', 'font-size': '38px' }"-->
        <!--              />-->
        <!--              <a-statistic-->
        <!--                value="本月新增线索数"-->
        <!--                value-style="font-size: 15px;color:#616c8d"-->
        <!--              >-->
        <!--                <template #prefix-->
        <!--                  ><PlusCircleOutlined-->
        <!--                    style="color: #5979f8; font-size: 22px"-->
        <!--                  />-->
        <!--                </template>-->
        <!--              </a-statistic>-->
        <!--            </a-col>-->
        <!--            &lt;!&ndash; :value="leadDetail.conversionNumber" 转化人数 &ndash;&gt;-->
        <!--            <a-col :span="8">-->
        <!--              <a-statistic-->
        <!--                :value="leadDetail.turnoverLeadNumber || 0"-->
        <!--                :value-style="{ color: '#5979F8', 'font-size': '38px' }"-->
        <!--              />-->
        <!--              <a-statistic-->
        <!--                value="本月流出线索数"-->
        <!--                value-style="font-size: 15px;color:#616c8d"-->
        <!--              >-->
        <!--                <template #prefix-->
        <!--                  ><MinusCircleOutlined-->
        <!--                    style="color: #5979f8; font-size: 22px"-->
        <!--                  />-->
        <!--                </template>-->
        <!--              </a-statistic>-->
        <!--            </a-col>-->
        <!--          </a-row>-->
        <!--        </a-col>-->
      </a-row>
    </a-spin>
  </div>
  <div class="iss-main">
    <a-spin :spinning="spinning" size="large">
      <search-form :items="items" @fnSearch="handleFnSearch" />
      <grid
        ref="gridRef"
        :columns="columns"
        rowKey="leadPoolDetailsId"
        allow-selection
        :url-params="{ leadPoolId: $route.params.id }"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1500, y: gridHeight }"
      >
        <!--        <template #operation="{ record }">-->
        <!--          <operation :options="options" :record="record" />-->
        <!--        </template>-->

        <!--        <template #scoreLevel="{ text }">-->
        <!--          <a-tag v-if="text === 'low'" color="lime">低</a-tag>-->
        <!--          <a-tag v-else-if="text === 'middle'" color="gold">中</a-tag>-->
        <!--          <a-tag v-else-if="text === 'high'" color="volcano">高</a-tag>-->
        <!--        </template>-->
      </grid>
    </a-spin>
  </div>
</template>

<script>
import { message, Spin, Row, Col, Space } from 'ant-design-vue';
// import {
//   LineChartOutlined,
//   PlusCircleOutlined,
//   MinusCircleOutlined,
// } from '@ant-design/icons-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import { useRoute } from 'vue-router';
import { reactive, toRefs, ref } from 'vue';
import clueApi from '@/api/clue';
import { downloadExcel } from '@/utils';
export default {
  components: {
    ASpin: Spin,
    ASpace: Space,
    SearchForm,
    Grid,
    ARow: Row,
    ACol: Col,
    // AStatistic: Statistic,
    // LineChartOutlined,
    // PlusCircleOutlined,
    // MinusCircleOutlined,
  },
  setup() {
    const inputRef = ref();
    const gridRef = ref();
    const route = useRoute();
    const state = reactive({
      spinning: false,
      search: {},
      leadDetail: {},
    });
    const detail = () => {
      const leadId = route.params.id;
      clueApi.getLeadDetail('', leadId).then(res => {
        state.leadDetail = res;
      });
    };
    const handleFnSearch = value => {
      state.search = value;
    };
    detail();
    return {
      ...toRefs(state),
      gridRef,
      items: [
        { key: 'fullName', label: '姓名' },
        {
          key: 'mobileNum',
          label: '手机号',
        },
        {
          key: 'email',
          label: '邮箱',
        },
        {
          key: 'company',
          label: '公司',
        },
        {
          key: 'scoreLevel',
          label: '所属分阶',
          type: 'select',
          dataset: [
            { value: 'low', label: '低' },
            { value: 'middle', label: '中' },
            { value: 'high', label: '高' },
          ],
        },
      ],
      columns: [
        { dataIndex: 'fullName', title: '姓名', width: 120, ellipsis: true },
        { dataIndex: 'company', title: '公司', width: 180, ellipsis: true },
        { dataIndex: 'industryLv1Code', title: '行业', ellipsis: true },
        { dataIndex: 'position', title: '职位', width: 120, ellipsis: true },
        { dataIndex: 'province', title: '省', width: 120, ellipsis: true },
        { dataIndex: 'city', title: '市', width: 120, ellipsis: true },
        { dataIndex: 'mobileNum', title: '手机', width: 120 },
        { dataIndex: 'email', title: '邮箱', width: 200, ellipsis: true },
        { dataIndex: 'source', title: '来源', width: 120, ellipsis: true },
        { dataIndex: 'score', title: '线索评分值', width: 120, ellipsis: true },
        { dataIndex: 'tag', title: '标签', ellipsis: true },
        {
          dataIndex: 'scoreLevel',
          title: '所属分阶',
          ellipsis: true,
          // slots: { customRender: 'scoreLevel' },
        },
      ],
      btnOperation: [
        {
          type: 'export',
          label: '导出',
          btnType: 'primary',
          icon: 'ExportOutlined',
          permission: 'clue:export',
          fnClick: ids => {
            if (ids.length) {
              clueApi
                .exportLeadPoolDetail('', ids)
                .then(({ name, data }) => downloadExcel(name, data));
            } else {
              message.info('请先选择数据');
            }
          },
        },
      ],
      gridHeight: document.body.clientHeight - 498,
      inputRef,
      url: clueApi.leadPoolDetailPageUrl,
      handleFnSearch,
    };
  },
};
</script>
<style lang="less" scoped>
.iss-main {
  height: calc(100vh - 206px);
  overflow-y: auto;
}
.iss-detail {
  height: auto;
  //overflow-y: auto;
}
</style>
