'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/account/leadPool/page',
  importLeadPoolDetailUrl: '/account/leadPool/importLeadPoolDetail',
  leadPoolDetailPageUrl: '/account/leadPoolDetail/page',
  add(code, data) {
    return http.post('/account/leadPool', data, { code });
  },
  delete(code, data) {
    return http.delete('/account/leadPool', { params: data, code });
  },
  update(code, data) {
    return http.put('/account/leadPool', data, { code });
  },
  getMemberGroupList(code) {
    return http.get('/account/memberGroup/list', { code });
  },
  getLeadScoreModel(code) {
    return http.get('/account/leadScoreModel/selectAllModel', { code });
  },
  downloadImportUserTemplate(code) {
    return http.get('/account/leadPool/downloadImportUserTemplate', {
      code,
      responseType: 'blob',
    });
  },
  exportLeadPool(code, data) {
    return http.post('/account/leadPool/exportLeadPool', data, {
      responseType: 'blob',
      code,
    });
  },
  // 详情导出
  exportLeadPoolDetail(code, data) {
    return http.post('/account/leadPoolDetail/exportLeadPoolDetail', data, {
      responseType: 'blob',
      code,
    });
  },
  getFormImportResultByCache(code) {
    return http.get('/account/leadPool/getContactImportResultByCache', {
      code,
    });
  },
  getLeadDetail(code, id) {
    return http.get(`/account/leadPool/${id}`, { code });
  },

  refreshScore(code, params) {
    return http.get('/account/leadPool/refreshScore', { params, code });
  },
  //  线索池取消执行
  CancelRefreshScore(code, params) {
    return http.get('/account/leadPool/cancelExec', { params, code });
  },
  updateAutoSwitch(code, { id, autoSwitch }) {
    return http.post(
      `/account/leadPool/updateAutoSwitch`,
      {},
      { code, params: { id, autoSwitch } }
    );
  },
};
